import React from 'react'
import EBookPage from '../views/ebook/EBookPage'
import AudioPage from "../views/audio-books/AudioPage";

const Audio = () => {
    return (
        <>
            <AudioPage/>
        </>
    )
}

export default Audio
